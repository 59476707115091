const FramedDrop = ({ src }: { src: string; }) => {
  return (
    <div className="relative w-[250px] h-[240px] ">
      <svg
        className="absolute inset-0 flex-shrink-0"
        width="248"
        height="230"
        viewBox="0 0 248 230"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M72.6917 221.545L72.6912 221.545C68.38 219.122 63.9385 216.058 59.3299 212.292L59.3286 212.291C55.9093 209.517 52.3918 206.344 48.7762 202.779L48.7761 202.779L0.680643 155.382L59.3956 85.8157L59.3957 85.8156L69.6967 73.6034L69.6968 73.6033L105.05 31.7115L105.051 31.7106C130.036 1.96035 160.25 -3.88947 186.208 4.4775C199.269 8.69546 211.358 16.4831 221.261 26.6596C231.186 36.8656 238.88 49.4227 243.114 63.1397L243.114 63.1398C252.138 92.3401 245.384 127.104 210.961 155.863L211.282 156.247L210.961 155.863L170.089 190.016C146.035 210.1 126.223 224.613 107.368 228.174C96.2445 230.273 84.994 228.481 72.6917 221.545Z"
          stroke="#0D3151"
        />
      </svg>

    
      <img
        className="absolute inset-0 w-[206px] h-[191px] flex-shrink-0 top-6 right-6"
        src={src}
        alt=""
      />

      
    </div>
  );
};

export default FramedDrop;
